@font-face {
    font-family: 'Birch Std';
    src: url('BirchStd.eot');
    src: url('BirchStd.eot?#iefix') format('embedded-opentype'),
        url('BirchStd.woff2') format('woff2'),
        url('BirchStd.woff') format('woff'),
        url('BirchStd.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

