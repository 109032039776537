.slick-track {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-slider,
.slick-list,
.slick-track,
.slick-slide > div {
  height: 100%;
}
