@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-Regular.eot');
    src: url('DMSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('DMSans-Regular.woff2') format('woff2'),
        url('DMSans-Regular.woff') format('woff'),
        url('DMSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

