.text-shadow {
  text-shadow: 2px 2px 4px black;
}
.text-shadow-md {
  text-shadow: 4px 4px 8px black;
}
.text-shadow-lg {
  text-shadow: 15px 15px 30px black;
}
.text-shadow-none {
  text-shadow: none;
}

.icon-shadow {
  filter: drop-shadow(2px 2px 2px black);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  @apply rounded-full;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(226, 226, 226, 1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(197, 197, 197);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input:-webkit-autofill {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

canvas {
  width: 100vw !important;
  height: 100vh !important;
  background-color: black;
}

.heading-2 {
  @apply text-2xl lg:text-5xl tracking-widest uppercase;
}

.heading-3 {
  @apply text-md lg:text-2xl tracking-widest uppercase;
}

.bounce {
  animation: bounce 4s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.no-scroll {
  @apply fixed top-0 left-0 h-screen w-full overflow-hidden;
}

/* SHOPIFY HIDE OTHER IFRAME */
.shopify-buy-frame iframe {
  display: none !important;
}

.shopify-buy-frame iframe:nth-child(1) {
  display: block !important;
}

.link {
  @apply text-shadow mx-2 md:mx-5 cursor-pointer text-[4vh] lg:text-[3vh] uppercase z-50 relative;
}

.position-center {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

iframe {
  width: 100%;
  @apply h-[30vh] lg:h-[60vh];
}

@media (min-width: 768px) {
  #music .slick-slide:nth-child(1),
  #music .slick-slide:nth-child(3) {
    pointer-events: none;
    opacity: 0;
  }
}

/* .hero-text span {
  @apply font-secondary !important;
} */

.for {
  position: relative;
}

.for::before {
  content: "SuperDripped Contest";
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  @apply text-dark text-[1.5vh] leading-[1vh] lg:text-[2.5vh] lg:leading-[2vh] font-primary font-bold whitespace-nowrap;
}

.btn {
  @apply bg-primary border-2 border-primary text-white rounded-sm py-[1vh] px-[3vh] uppercase font-bold hover:text-primary hover:bg-transparent;
}
